"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _configs = require("./configs");
var _default = exports["default"] = {
  queries: _configs.queries,
  i18n: {
    url: 'https://resources-saj6.s3-sa-east-1.amazonaws.com/pj/{{lng}}/{{ns}}.json',
    language: 'pt-BR'
  },
  keycloak: {
    realm: window.keycloakRealm,
    clientId: window.keycloakClientId,
    url: window.keycloakUrl
  },
  multitenancy: {
    enabled: true,
    tenantName: window.multitenancyTenantName
  },
  apollo: {
    url: window.apolloUrl,
    apm: {
      enabled: false,
      serviceName: 'pj-word-addin-frontend',
      serviceVersion: '0.0.5',
      serverUrl: 'https://apm-unj.softplan.com.br',
      pageLoadTransactionName: 'pj-word-addin-frontend',
      distributedTracingOrigins: 'https://unj-core.dev.softplan.com.br/apollo'
    },
    intercept: {
      error: true
    }
  },
  header: {
    appName: 'SAJ Add-in'
  },
  externalUrls: {
    contentDocumentsAction: window.externalUrlsContentDocumentsAction
  }
};