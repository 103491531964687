import { gql } from '@bayon/fetch';

export const field_groups = gql`
  query field_groups($filter: CON_campos_agrupados_por_tipo_list_filter) {
    campos_agrupados_por_tipo(filter: $filter) {
      items {
        id_tipo
        descricao_tipo
        estrategia_processamento
        campos {
          id
          nome
          sucesso
          valores
          descricao
        }
      }
    }
  }
`;
