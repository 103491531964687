export const usuario = {
  query: `{
    usuario {
      lotacao_principal {
        sequencial
      }
    }
  }
`,
};
