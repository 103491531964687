import { gql } from '@bayon/fetch';

export const field_options = gql`
  query field_options($filter: CON_campo_input_graph_type_input) {
    campo_particulas(filter: $filter) {
      nome
      descricao
      valores
      sucesso
      motivo
    }
  }
`;
