import { gql } from '@bayon/fetch';

export const process_information = gql`
  query consulta_processo($id: ID) {
    detalhes_do_processo(filter: { id: $id }) {
      id
      nosso_numero
      numero_judicial
    }
  }
`;
